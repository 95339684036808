import { HygraphPagesQuery } from '@graphcommerce/graphcms-ui'
import { Row } from '@graphcommerce/next-ui'
import { Container, List, ListItemButton, ListItemText, Theme, SxProps } from '@mui/material'
import { normalizeHref } from '../../normalizeHref'
import { AccordionMenu } from './AccordionMenu'

type MenuListProps = HygraphPagesQuery & { sx?: SxProps<Theme> }

export function SidebarMenu(props: MenuListProps) {
  const { sx, pages } = props
  const page = pages?.[0]

  if (!page?.sidebarMenuList?.sidebarTitle) return null

  return (
    <Container
      sx={(theme) => ({
        p: '0 !important',
        pr: { xs: 0, md: `${theme.spacings.sm} !important` },
        maxWidth: { xs: 'unset', md: '300px !important' },
        float: 'left',
        mb: theme.spacings.md,
      })}
    >
      <Row sx={[{ p: '0 !important', m: 0 }, ...(Array.isArray(sx) ? sx : [sx])]}>
        <AccordionMenu title={page?.sidebarMenuList?.sidebarTitle}>
          {page?.sidebarMenuList?.menuListItems?.map((item) => (
            <List disablePadding key={item.url}>
              <ListItemButton
                href={normalizeHref(item.url)}
                sx={(theme) => ({
                  p: '0px !important',
                  '&:hover, &:focus, &:active': {
                    color: theme.palette.primary.main,
                    background: 'unset',
                  },
                })}
              >
                <ListItemText primary={item.title} />
              </ListItemButton>
              {item.subPageLinks.map((subitem) => (
                <List disablePadding key={subitem.url}>
                  <ListItemButton
                    href={normalizeHref(subitem.url)}
                    sx={(theme) => ({
                      p: `0 0 0 ${theme.spacings.xxs} !important`,
                      '&:hover, &:focus, &:active': {
                        color: theme.palette.primary.main,
                        background: 'unset',
                      },
                    })}
                  >
                    <ListItemText primary={subitem.title} />
                  </ListItemButton>
                </List>
              ))}
            </List>
          ))}
        </AccordionMenu>
      </Row>
    </Container>
  )
}
